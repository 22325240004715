<template>
  <div v-if="quoteDetailObj !== null" class="mt-4">
    <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full">
      <div class="flex justify-between items-center">
        <h1 class="heading-2 text-center">Quotation #{{quoteDetailObj.quotationMasterId}}</h1>
        <div class="flex items-center gap-3">
          <div class=" text-primary heading-3 cursor-pointer bg-white px-3 py-1 rounded-xl" @click.stop="isEmailSending()">
            <i class="fas fa-envelope"></i>
          </div>
          <div class="">
            <div class="relative">
              <div class=" text-primary heading-3 cursor-pointer bg-white px-3 py-1 rounded-xl" @click.stop="showMenuList = true">
                <i class="fas fa-ellipsis-v"></i>
              </div>
              <div v-if="showMenuList">
                <div v-click-outside="closeList">
                  <div class=" bg-white absolute z-10 right-3 shadow-2xl rounded top-10 w-44">
                      <div class="p-2 text-text2 font-medium text-center">
                        <div class="p-2 cursor-pointer hover:bg-blue-50" v-if="quoteDetailObj.statusName !== 'Approved'" @click="editQuote()">
                          <span class="py-1">Edit Quote</span>
                        </div>
                        <div class="p-2 cursor-pointer hover:bg-blue-50" @click="approveQuote()" v-if="quoteDetailObj.statusName !== 'Approved'">
                          <span class="py-1">Approve Quote</span>
                        </div>
                        <div class="p-2 cursor-pointer hover:bg-red-50" @click="removeConfirm = true;showMenuList = false">
                          <span class="py-1 text-error">Delete Quote</span>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="py-2"></div>
         <div class="lg:grid grid-cols-12 gap-4">
            <div class="col-span-4 2xl:col-span-4">
              <div class="card bg-white rounded-xl p-4 mb-4">
                <div>
                    <p class="text-text1 heading-2 mb-4 font-bold">{{quoteDetailObj.companyName}}</p>
                </div>
                <div class=" flex justify-between">
                  <div class=" mb-4">
                    <p class="text-gray4">Quote Date</p>
                    <p class="font-bold text-text1">{{quoteDetailObj.quoteDate | formatForDatePicker}}</p>
                </div>
                <div class=" mb-4">
                    <p class="text-gray4 text-right">Expire Date</p>
                    <p class="font-bold text-text1 text-right">{{quoteDetailObj.expiryDate | formatForDatePicker}}</p>
                </div>
                
                </div>
                <div class=" mb-4">
                    <p class="text-gray4">Status</p>
                    <div class="flex items-center">
                      <p :style="`color: ${quoteDetailObj.statusColor}`" class="font-bold text-text2">{{quoteDetailObj.statusName}}</p>
                      <p class="text-primary cursor-pointer heading-6 pl-2" @click="openSignature()" v-if="quoteDetailObj.statusName === 'Approved'">(View Signature)</p>
                    </div>
                  </div>
                <div class="text-gray4">
                  <div>
                      <p>{{quoteDetailObj.isLead ? 'Lead' : 'Customer'}}</p>
                      <div class="flex justify-between mb-2">
                      <div class="">
                      <span @click="redirectToCust" class="cursor-pointer text-primary font-semibold">{{quoteDetailObj.companyName}} </span>
                      </div>
                      <div v-if="quoteDetailObj.customerHotNotes !== ''" class=" text-right flex items-center">
                        <span class="cursor-pointer text-primary heading-7" @click.stop="showHotNOtes = !showHotNOtes"> {{showHotNOtes ? 'Hide' : 'Show'}} hot notes</span>
                      </div>
                    </div>
                    <div v-if="showHotNOtes" class="">
                        <span class="cursor-pointer text-secondary">
                          {{quoteDetailObj.customerHotNotes}}
                        </span>
                      </div>
                  </div>
                </div>
                <!-- <div >
                  <a class="text-primary cursor-pointer heading-6" :href="quoteDetailObj.quotationLinkHash">{{quoteDetailObj.quotationLinkHash}}</a>
                </div> -->
              </div>
            </div>
            <div class=" col-span-8 2xl:col-span-6 mb-10">
                <div class="card bg-white rounded-xl p-4 mb-4">
                   <div  class="" v-if="quoteDetailObj.itemList.length > 0">
                     <p class=" text-gray4 heading-4 font-bold mb-4">Items list:</p>
                     <div class="px-4 cursor-pointer hover:bg-gray1 border-b rounded py-2" v-for="(data, index) in quoteDetailObj.itemList" :key="index" @click="showItemDetail(data)">
                        <div class="text-text2 text-sm flex mb-2 font-bold justify-between">
                            <p class="pr-4" >{{data.itemName}}</p>
                            <p class="" >{{data.itemAmount | amountOnlyNumber}}</p>
                        </div>
                        <div class="text-text2 flex">
                            <p class="w-32 sm:w-72 heading-6">{{data.itemPrice | amountOnlyNumber}}</p>
                            <span class="w-72 heading-6">Qty:{{data.itemQty}} <span class="pl-4" v-if="data.isTaxable">Taxed</span></span>
                        </div>
                        <div class="text-text2 flex">
                            <p class="heading-7 text-gray4">{{data.itemDescription.substr(0, 100)}} <span v-if="data.itemDescription.length > 100">...</span></p>
                        </div>
                     </div>
                    </div>
                    <div class="flex justify-between py-1 pt-2 px-4">
                      <p class="text-text2 font-bold w-full text-right">Total:</p>
                      <p class="text-text2 font-bold w-full text-right">{{quoteDetailObj.subTotal.toFixed(2) | amountOnlyNumber}}</p>
                    </div>
                    <div class="flex justify-between pt-2 px-4" v-if="quoteDetailObj.isDepositPer">
                        <p class="text-text2 font-bold w-full text-right ">Deposit ({{ quoteDetailObj.depositAmt }}%):</p>
                        <p class="text-text2 font-bold w-full text-right">{{((quoteDetailObj.grandTotal * quoteDetailObj.depositAmt) / 100) | amountFormaterWithToFix}}</p>
                    </div>
                    <div class="flex justify-between pt-2 px-4" v-if="!quoteDetailObj.isDepositPer">
                        <p class="text-text2 font-bold w-full text-right">Deposit Amount:</p>
                        <p class="text-text2 font-bold w-full text-right">{{(quoteDetailObj.depositAmt) | amountFormaterWithToFix}}</p>
                    </div>
                </div>
                <div class="card bg-white rounded-xl p-4 mb-4" v-if="quoteDetailObj.customerNotes !== ''">
                  <div class="flex items-center justify-between">
                    <p class=" heading-5 font-bold">Notes on quote</p>
                    <p class=" heading-6 font-semibold text-primary cursor-pointer" @click="isShowCust = !isShowCust">{{isShowCust ? 'Hide' : 'View'}}</p>
                  </div>
                  <span v-if="isShowCust" class="text-text1 heading-5">{{quoteDetailObj.customerNotes}}</span>
                </div>
                <div class="card bg-white rounded-xl p-4 mb-4" v-if="quoteDetailObj.internalNotes !== ''">
                  <div class="flex items-center justify-between">
                    <p class=" heading-5 font-bold">Internal Note</p>
                    <p class=" heading-6 font-semibold text-primary cursor-pointer" @click="isShowInternal = !isShowInternal">{{isShowInternal ? 'Hide' : 'View'}}</p>
                  </div>
                  <span v-if="isShowInternal" class="text-text1 heading-5">{{quoteDetailObj.internalNotes}}</span>
                </div>
            </div>
        </div>
    </div>
    <ConfirmBox @confirmBoxHandler="deleteSelfiConfirm" v-if="removeConfirm" :message="'Once deleted cannot undone.'" :title="'Are you sure?'" />
    <ApproveQuote v-if="showSignaturePopup"/>
    <SendBokkingCon :entitySlug="'quotation'" :detailId="parseInt(this.$route.params.quoteId)" :customerId="quoteDetailObj.customerId" v-if="isEmailSend"  />
    <div v-if="openSignaturePopup">
      <div class="popup_overlay px-4">
      <div class="custom_dialog rounded-xl" style="width: 600px; overflow: auto; max-height: 90%;">
        <div class="relative bg-white">
          <div>
            <div class="bg-primary flex justify-between items-center p-3">
              <div class=" font-medium text-white heading-3 flex items-center">
                Signature <div v-if="isLoading" class="loader ease-linear ml-2"></div>
              </div>
              <div class=" text-primary heading-3 cursor-pointer bg-white px-3 py-1 rounded-xl" @click.stop="openSignaturePopup = false">
                <i class="fas fa-times"></i>
              </div>
            </div>
            <div class="m-4 text-text2  rounded flex place-content-center">
              <img class="border border-dashed border-text2" :src="srcUrl">
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import {getCookies} from '@/utils/cookies'
import {downloadFile, checkFileTypeForPreview} from '@/utils/common.js'
import axios from 'axios'
import * as config from '@/config.js'
import SendBokkingCon from '@/View/components/SendBokkingCon.vue'
import ApproveQuote from '@/View/quotation/components/approveQuotes.vue'
import MyJobApp from "@/api/MyJobApp.js";
import ConfirmBox from '@/View/components/ConfirmBox.vue'
import {FilterPermissions} from '@/utils/Permissions.js'
export default {
  components: {
    ConfirmBox,
    SendBokkingCon,
    ApproveQuote
  },
  data() {
    return {
      isCustomerPermission: FilterPermissions('customer'),
      isLeadPermission: FilterPermissions('lead'),
      canEmailPermission: FilterPermissions('quote_email'),
      isLoading: false,
      baseUrl: config.API_ROOT,
      srcUrl: '',
      openSignaturePopup: false,
      isEmailSend: false,
      showSignaturePopup: false,
      isShowCust: false,
      isShowInternal: false,
      showMenuList: false,
      quoteDetailObj: null,
      vissibleTaxId: 0,
      selectedItemDetails: null,
      itemdetailShow: false,
      removeConfirm: false,
      itemBreakDonwShow: false,
      showHotNOtes: false,
    };
  },
  created() {},
  mounted() {
    document.body.style = 'overflow: visible;'
    this.getQuoteDetails()
    this.$root.$on('bookingConfirmationHandler', () => {
      document.body.style = 'overflow: visible;'
      this.isEmailSend = false
    })
    this.$root.$on('closeApproveQuote', (data) => {
      if (data) {
        this.getQuoteDetails()
        this.showSignaturePopup = false
      }
      this.showSignaturePopup = false
    })
    this.$root.$on('closeItemDetailHandler', (data) => {
        console.log(data);
        this.itemdetailShow = false
        this.selectedItemDetails = null
    })
    this.$root.$on('closeBreackDownHandler', (data) => {
        console.log(data);
        this.itemBreakDonwShow = false
        this.vissibleTaxId = 0
    })
    // this.$root.$on('confirmBoxHandler', (response) => {
    //   console.log('click')
    //   document.body.style = 'overflow: visible;'
    //   if (response) {
    //     this.removeConfirm = false
    //     this.deleteQuote()
    //   }
    //   this.removeConfirm = false
    // })
  },
  methods: {
    isEmailSending () {
      console.log('this.canEmailPermission', this.canEmailPermission)
      if (this.canEmailPermission) {
        document.body.style = 'overflow: hidden;'
        this.isEmailSend = true
      } else {
        this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to Send Email`})
      }
    },
    deleteSelfiConfirm (response) {
      if (response) {
        this.removeConfirm = false
        this.deleteQuote()
      }
      this.removeConfirm = false
    },
    closeList () {
      this.showMenuList = false
    },
    approveQuote () {
      this.showMenuList = false
      this.showSignaturePopup = true
    },
    editQuote () {
      this.$router.push({name: 'EditQuote', params: {quoteId: this.quoteDetailObj.quotationMasterId}})
    },
    redirectToCust () {
      if (this.isCustomerPermission || this.isLeadPermission) {
        if (this.quoteDetailObj.isLead) {
          this.$router.push({name: 'LeadDetail', params: {leadId: this.quoteDetailObj.customerId}})
        } else {
          this.$router.push({name: 'customerDetails', params: {customerId: this.quoteDetailObj.customerId}})
        }
      } else {
        this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Customer Detail`})
      }
    },
    paymentDetail (id) {
      this.$router.push({name: 'paymentDetails', params: {paymentId: id}})
    },
    showBreakDownDetail (taxId) {
        this.itemBreakDonwShow = true
        this.vissibleTaxId = taxId
    },
    openSignature () {
      this.downLoadDocument()
      // this.srcUrl = this.baseUrl + '/api/v1/notes/getImageWeb' + '?authToken=' + getCookies('jobAuthToken') + '&fileVaultId=' + this.quoteDetailObj.signFileVaultId
      this.openSignaturePopup = true
    },
    showItemDetail (detail) {
        this.itemdetailShow = true
        this.selectedItemDetails = detail
    },
    async downLoadDocument () {
      this.isLoading = true
        let Auth = getCookies('jobAuthToken')
        let url = this.baseUrl + '/api/v1/notes/getImageWeb'
        let formData = new FormData()
        formData.append('authToken', Auth)
        formData.append('fileVaultId', this.quoteDetailObj.signFileVaultId)
        const cancelToken = axios.CancelToken
        const source = cancelToken.source()
        this.$store.dispatch('CancelDownloadRequest', source)
        let response = await downloadFile(formData, url, source)
        if (response.status === 200) {
          this.$store.dispatch('CancelDownloadRequest', null)
          if (checkFileTypeForPreview(response.headers['content-type'])) {
            this.srcUrl = URL.createObjectURL(response.data)
          }
          this.isLoading = false
          this.$store.dispatch('SetDownloadLoader', false)
          this.$store.dispatch('SetDownloadMessage', '')
        } else {
          this.isLoading = false
          this.$store.dispatch('CancelDownloadRequest', null)
          this.$store.dispatch('SetDownloadMessage', '')
          this.$store.dispatch('SetDownloadLoader', false)
        }
      },
    deleteQuote () {
      this.$store.dispatch("SetLoader", {  status: true,  message: "Loading...",});
      MyJobApp.deleteQuotation(
        parseInt(this.$route.params.quoteId),
        (response) => {
          if (this.quoteDetailObj.isLead) {
            this.$router.push({name: 'LeadDetail', params: {leadId: this.quoteDetailObj.customerId}})
          } else if (!this.quoteDetailObj.isLead) {
            this.$router.push({name: 'customerDetails', params: {customerId: this.quoteDetailObj.customerId}})
          } else {
            this.$router.push({name: 'quotation'})
          }
          // if (this.quoteDetailObj.isLead) {
          //   this.$router.push({name: 'LeadDetail', params: {leadId: this.quoteDetailObj.customerId}})
          // } else {
          //   this.$router.push({name: 'quotation'})
          // }
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    getQuoteDetails() {
      console.log("call", this.$route);
      this.$store.dispatch("SetLoader", { status: true, message: "Loading..." })
      MyJobApp.GetQuoteDetail(
        parseInt(this.$route.params.quoteId),
        (response) => {
          console.log("response", response)
          this.quoteDetailObj = response.Data
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
  },
  beforeDestroy() {
    this.$root.$off("bookingConfirmationHandler");
    this.$root.$off("confirmBoxHandler");
    this.$root.$off("closeBreackDownHandler");
    this.$root.$off("closeItemDetailHandler");
    this.$root.$off("closeApproveQuote");
  },
};
</script>
<style scoped>
.table_col {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.name_col {
    min-width: 200px;
    max-block-size: 200px;
}
.des_col {
    min-width: 250px;
    max-block-size: 250px;
}
.pric_col {
    min-width: 100px;
    max-block-size: 100px;
}
.qnty_col {
    min-width: 100px;
    max-block-size: 100px;
}
.amt_col {
    min-width: 100px;
    max-block-size: 100px;
}
.loader {
    border: 3px solid rgb(255, 247, 247);
    border-radius: 50%;
    border-top: 3px solid #484848;
    width: 27px;
    height: 27px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
</style>