import { render, staticRenderFns } from "./quoteDetail.vue?vue&type=template&id=718419c9&scoped=true&"
import script from "./quoteDetail.vue?vue&type=script&lang=js&"
export * from "./quoteDetail.vue?vue&type=script&lang=js&"
import style0 from "./quoteDetail.vue?vue&type=style&index=0&id=718419c9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "718419c9",
  null
  
)

export default component.exports