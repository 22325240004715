<template>
  <div class="popup_overlay px-4">
      <div class="custom_dialog rounded-xl" style="width: 800px; overflow: auto; max-height: 90%;">
        <div class="relative bg-white">
          <div>
            <div class="bg-primary flex justify-between items-center p-3">
              <div class=" font-medium text-white heading-3">
                Add Signature
              </div>
              <div class="flex items-center">
                <span class=" font-bold text-white heading-6 cursor-pointer" @click="undoSign()">
                  <i class="fa-solid fa-rotate-left"></i>Undo
                </span>
                <span class=" font-bold text-white heading-6 cursor-pointer pl-4" @click="toDataURL()">
                  Reset
                </span>
              </div>
            </div>
            <div class="m-4 text-text2 border border-dashed border-text2 rounded" style="cursor: crosshair;">
              <VueSignaturePad style="background-color:#F3F3F3"
                id="signature"
                height="200px"
                ref="signaturePad"
                :options="strokeOptions"
              />
            </div>
            <!-- <div class="m-4 text-text2 border border-dashed border-text2 rounded" style="cursor: crosshair;">
              <VPerfectSignature @onEnd="onEnd" backgroundColor="#F3F3F3" :stroke-options="strokeOptions" ref="signaturePad" />
            </div> -->
            <div class="flex justify-end p-3">
              <div class="text-rigth flex gap-2">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="closePopup(false)"/>
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="saveSignature(true)"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import MyJobApp from "@/api/MyJobApp.js";
// import VPerfectSignature from 'v-perfect-signature'
import Button from '@/View/components/globalButton.vue'
export default {
  props: ['Depobject'],
  components: {
    Button,
    // VPerfectSignature
  },
  data() {
    return {
      dataURL: '',
      strokeOptions: {
        penColor: '#000',
        size: 6,
        thinning: 0.7,
        smoothing: 0.5,
        streamline: 0.5
      },
    };
  },
  mounted() {
  },
  watch: {
  },
  beforeDestroy() {
  },
  methods: {
    saveSignature () {
      this.dataURL = this.$refs.signaturePad.saveSignature()
      if (this.dataURL.data === undefined || this.dataURL.isEmpty === true) {
        this.$store.dispatch('SetAlert', {showAlert: true, message: 'Signature is Required', color: 'error'})
      } else {
        this.$store.dispatch("SetLoader", { status: true, message: "Loading..." })
        MyJobApp.approveQuotes(
          parseInt(this.$route.params.quoteId),
          this.dataURL.data,
          (response) => {
            this.$root.$emit('closeApproveQuote', true)
            this.$store.dispatch("SetAlert", { status: true, message: response.message, color: 'success'});
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          },
          (error) => {
            this.$store.dispatch("SetAlert", { status: true, message: error.message, color: 'error'});
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          }
        )
      }
    },
    onEnd() {
      console.log('on end')
    },
    undoSign () {
      this.$refs.signaturePad.undoSignature();
    },
    toDataURL() {
      this.$refs.signaturePad.clearSignature()
    },
    depositBtn (value) {
      this.depIsPer = value
      this.depRawAmount = 0
      this.depErr = ''
    },
    closePopup (value) {
      this.$root.$emit('closeApproveQuote', value)
    },
  }
};
</script>
<style scoped>
.quote_name_col {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
#sig-canvas {
  border: 2px dotted #CCCCCC;
  border-radius: 15px;
  cursor: crosshair;
}
</style>